import React, {useRef, useEffect} from 'react';
import Navigation from './components/Navigation';
import {Outlet} from "react-router-dom";
import CookieBanner from './components/CookieBanner';
import Header from './components/Header';

const App = () => {
  const parallaxObject = useRef(null);
  const outPut = useRef(null);

  useEffect(() => {
      document.addEventListener("mousemove", (e) => parallax(e));

      function parallax(e) {
          const obj = parallaxObject.current;
          obj.style.setProperty("--x", e.clientX);
          obj.style.setProperty("--y", e.clientY);
          // outPut.current.textContent = e.clientX;
      }
      return (() => {
          document.removeEventListener("mousemove", (e) => parallax(e));
      });
  },[]);

  useEffect(() => {
      window.addEventListener("deviceorientation", (event) => handleOrientation(event));
      const obj = parallaxObject.current;
      const maxX = window.clientWidth - obj.clientWidth;
      const maxY = window.clientHeight - obj.clientHeight; 

      const handleOrientation = (event) => {
        let x = event.beta; // In degree in the range [-180,180)
        let y = event.gamma; // In degree in the range [-90,90)
        
        if (x > 90) x = 90;
        if (x < -90) x = -90;

        x += 90;
        y += 90;

        const obj = parallaxObject.current;
      
        outPut.current.textContent = `beta : ${x}\n`;
        outPut.current.textContent += `gamma: ${y}\n`;  
        obj.style.setProperty("--x", (maxY * y) / 180 - 10);
        obj.style.setProperty("--y", (maxX * x) / 180 - 10);
      }
      return (() => {
          window.removeEventListener("deviceorientation", (event) => handleOrientation(event));
      });
  },[]);

  const myStyle = {
    position: "fixed",
    color: "white",
    bottom: '0',
    left: "50%",
  }

  return (
      <div className="main-container">
          <div className='parallaxObj' ref={parallaxObject}>
                <Header/>
                <Navigation/>
                <div style={myStyle} ref={outPut}>test:</div>
            </div>
            <div className="content-container">
                <Outlet/>
            </div>
            <CookieBanner/>
      </div>
  );
}

export default App;