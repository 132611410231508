import React from "react";
import * as ReactDOM from "react-dom/client";
import '../scss/style.scss';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import App from "./App";
import Shows from "./pages/Shows";
import Music from "./pages/Music";
import Follow from "./pages/Follow";
import ErrorPage from "./pages/error-page";
  
  const router = createBrowserRouter([
      {
        path: "/",
        element: <App/>,
        errorElement: <ErrorPage />,
        children: [
            {
                errorElement: <ErrorPage />,
                children:[
                    {
                        path: "shows",
                        element: <Shows />,
                    },
                    {
                        path: "music",
                        element: <Music />,
                    },
                    {
                        path: "follow",
                        element: <Follow />,
                    },
                ],
            }
        ],
      },
  ]);
  

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);