import React from 'react';

const Follow = () => {
 
  return (
    <div>
        music
    </div>
  );
}

export default Follow ;