import React, { useEffect, useState } from "react"
import CookieConsent from "react-cookie-consent";

const CookieBanner = () => {

    const cookieAccepted = () => {

    }

    return(
      <CookieConsent
        debug={true}
        disableStyles={true}
        buttonClasses="btn btn-primary"
        containerClasses="cookie_container"
        contentClasses="text-capitalize"
        onAccept={(acceptedByScrolling) => {
          if (!acceptedByScrolling) {
              cookieAccepted()
          }
        }}>
          We use both our own and third party cookies on this website to enhance your browsing experience, 
          measure our audience and provide you with tailored ads. To find out more about how we use cookies 
          </CookieConsent>
    )
}

export default CookieBanner;