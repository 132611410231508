import React, {useEffect} from 'react';

const Shows = () => {
  useEffect(() => {
    const script = document.createElement('script');
  
    script.src = "//widget.songkick.com/8318353/widget.js";
    script.async = true;
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, []);
 
  return (
    <div>
        Shows

        <a href="https://www.songkick.com/artists/8318353" 
            className="songkick-widget" data-theme="dark"
            data-track-button="on" data-detect-style="true"
            data-background-color="transparent" data-locale="en">
              Jason Waterfalls tour dates
        </a>
    </div>
  );
}

export default Shows;