
const prod = {
    url: {
        API_URL: "",
        API_CMS_URL: 'cms.https://jasonwaterfalls.nl'
    }
};
const dev= {
    url: {
        API_URL: 'https://jasonwaterfalls.nl',
        API_CMS_URL: 'https://cms.jasonwaterfalls.nl'
    }
};
export const config = process.env.NODE_ENV === 'development' ? dev : prod;