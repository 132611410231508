import React from 'react';

const Music = () => {
 
  return (
    <div>
        music
    </div>
  );
}

export default Music;