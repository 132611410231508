import React, { useEffect} from 'react';
import { Link } from "react-router-dom";
import { client } from '../helpers/client';


const Navigation = () => {
    useEffect(() => {
        let isCancelled = false;

        /**
         * api call
         * @return {Promise<void>}
         */
        async function getPostData() {
            const response = await client('/wp-json/wp/v2/posts');
            if (isCancelled) return;
        }
        getPostData();

        return () => {
            isCancelled = true;
        };
    },[]);
    
    return (
        <nav className="navigation-side">
            <div>
                <h3>
                    <Link to={``}>HOME</Link>
                </h3>
            </div>
            <div>
                <h3>
                    <Link to={`shows`}>SHOWS</Link>
                </h3>
            </div>
            <div>
                <h3>
                    <Link to={`music`}>MUSIC</Link>
                </h3>
            </div>
            <div>
                <h3>
                    <a href="https://shop.jasonwaterfalls.nl">STORE</a>
                </h3>
            </div>
            <div>
                <h3>
                    <Link to={`follow`}>FOLLOW</Link>
                </h3>
            </div>
        </nav>
    );
};

export default Navigation;