import React, { useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { client } from '../helpers/client';


const Header = () => {
    const logoRef = useRef(null);

    useEffect(() => {
        const text = logoRef.current;
        const numLetters = text.length; // how many letters?
        
        function randomBlurize() {
            // Call itself recurssively
            setTimeout(randomBlurize, 100);          
        } // Call once
        randomBlurize();
    },[]);
    
    return (
        <div className="header-container">
          <h2 ref={logoRef}>JASONWATERFALLS</h2>
       </div>
    );
};

export default Header;